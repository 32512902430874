import React, { useState, useEffect, useRef } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

import { capitalizeClubName, SurveyButton, SurveyNavigationButtons} from './SurveyComponents';
import { IntroPage, SimpleTextPage, SimpleInputTextField, SimpleChoicePage, TextWithImage, TextWithSideImage, MultiSelectPage, FileUploadPage, EndPage } from './SurveyScreens';
import Loading from '../Loading';
import { myAxiosInstance } from '../axiosConfig';


import WhiteLogo from '../Images/logo_white.png';
import PoolGraphic from '../Images/pool_graphic.png';
import PlatformPicture from '../Images/platform_picture.png';
import { faL } from '@fortawesome/free-solid-svg-icons';


function CommunitySurvey({ people, spreadsheet }) {
    // Survey variables
    const [stage, setStage] = useState(1);
    const [isTypingDone, setIsTypingDone] = useState(true);

    // Seed for random sampling
    const seedRef = useRef(Math.random()); // Generate a random seed once on mount

    // User Variables
    const name = useRef("");
    const [nameError, setNameError] = useState(false);

    const collegeBestTime = useRef("");
    const [collegeBestTimeError, setCollegeBestTimeError] = useState(false);

    const collegeNetworkStrength = useRef("");
    const [collegeNetworkStrengthError, setCollegeNetworkStrengthError] = useState(false);

    const keepInContact = useRef("");
    const [keepInContactError, setKeepInContactError] = useState(false);

    const diverseEnvironment = useRef("");
    const [diverseEnvironmentError, setDiverseEnvironmentError] = useState(false);

    const careerOptionality = useRef("");
    const [careerOptionalityError, setCareerOptionalityError] = useState(false);

    const poolingExcitesChoice = useRef("");
    const [poolingExcitesChoiceError, setPoolingExcitesChoiceError] = useState(false);

    const selectedPeople = useRef([]);
    const [selectedPeopleError, setSelectedPeopleError] = useState(false);

    const bigClubPool = useRef("");
    const [bigClubPoolError, setBigClubPoolError] = useState(false);

    const selectedPeoplePool = useRef("");
    const [selectedPeoplePoolError, setSelectedPeoplePoolError] = useState(false);
    
    const phone = useRef("");
    const [phoneError, setPhoneError] = useState(false);

    const linkedin = useRef("");

    const resume = useRef(null);

    const bespokeInterest = useRef("");
    const [bespokeInterestError, setBespokeInterestError] = useState(false);

    const otherComments = useRef("");

    // Loading
    const [reload, setReload] = useState(1);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { showBoundary } = useErrorBoundary();

    // Screen size 
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    // Animation Settings
    const animationSettings = {
        variants: {
            initial: { opacity: 0, y: 300 }, // Slide in from below
            animate: { opacity: 1, y: 0 },   // Slide to position
            exit: { opacity: 0, y: -300 },   // Slide out to the top
        },
        transition: {
            initial: { duration: 0.3 },      // Duration for entering animation
            exit: { duration: 1.2 },         // Duration for exiting animation
        },
    }

    const introAnimationSettings = {
        variants: {
            initial: { opacity: 0, y: -300 },
            animate: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: -300 },
        },
        transition: {
            initial: { duration: 1.2 },
            exit: { duration: 1.2 },
        },
    }

    const typingSpeed = 81;

    // Get the club name from the URL
    const { school, club_name } = useParams();
    const navigate = useNavigate();

    // Function to generate a random sample based on the seed
    const getRandomSample = (arr, size, seed) => {
        const seededRandom = (seed) => {
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
        };

        const shuffled = [...arr]
        .map((value, index) => ({
            value,
            rand: seededRandom(seed + index),
        }))
        .sort((a, b) => a.rand - b.rand)
        .map((obj) => obj.value);

        return shuffled.slice(0, size);
    };

    const peopleOptions = getRandomSample(people, 20, seedRef.current);

    const collectSurveyData = () => {
        const surveyData = {
            Name: name.current || "",
            SelectedPeoplePool: selectedPeoplePoolOptions[selectedPeoplePool.current] || "",
            BespokeInterest: bespokeIntroOptions[bespokeInterest.current] || "",
            Phone: phone.current || "",
            Linkedin: linkedin.current || "",
            Resume: resume.current?.name || "",
            CollegeBestTime: collegeSecondOptions[collegeBestTime.current] || "",
            CollegeNetworkStrength: collegeThirdOptions[collegeNetworkStrength.current] || "",
            KeepInContact: careerPageOneOptions[keepInContact.current] || "",
            DiverseEnvironment: careerPageTwoOptions[diverseEnvironment.current] || "",
            CareerOptionality: careerPageThreeOptions[careerOptionality.current] || "",
            PoolingExcitesChoice: poolingExcitesOptions[poolingExcitesChoice.current] || "",
            BigClubPool: bigClubPoolOptions[bigClubPool.current] || "",
            SelectedPeople: selectedPeople.current || [],
            OtherComments: otherComments.current || "",  
        };
    
        // Convert all values to strings
        const formattedData = Object.fromEntries(
            Object.entries(surveyData).map(([key, value]) => [
                key,
                Array.isArray(value) ? value.join(", ") : String(value),
            ])
        );    
    
        return formattedData;
    };

    // Set the background
    useEffect(() => {
        // Set background color for the body when the SurveyPage is rendered
        document.body.style.backgroundColor = "#02002B"; // Your desired background color

        // Cleanup function to reset the background color
        return () => {
            document.body.style.backgroundColor = ""; // Reset to default
        };
    }, []);

    // Keydown for Enter
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Enter" && isTypingDone) {
                handleNext();
            }
        };

        // Attach the event listener
        document.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isTypingDone]); // Re-run the effect if isTypingDone changes

    // Refresh
    useEffect(() => {
        const getSurvey = async () => {
            try {
                setStage(1);
                setIsTypingDone(true);
                setNameError(false);
                setCollegeBestTimeError(false);
                setCollegeNetworkStrengthError(false);
                setKeepInContactError(false);
                setDiverseEnvironmentError(false);
                setCareerOptionalityError(false);
                setPoolingExcitesChoiceError(false);
                setBigClubPoolError(false);
                setSelectedPeopleError(false);
                setPhoneError(false);
            } catch (error) {
                console.error('Error fetching tutorial details', error.response?.data || error);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };
    
        // Fetch survey if navigation check passes
        getSurvey();

    }, [school, club_name, navigate, reload, showBoundary]);

    // Validating
    // Validate a string input
    const validateString = (value, setError) => {
        if (!value.trim()) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    };

    // Validate an option (for example, a dropdown or choice)
    const validateOption = (value, setError) => {
        if (!value) { // Adjust condition based on what a "valid" option is (e.g., not null or undefined)
            setError(true);
            return false;
        }
        setError(false);
        return true;
    };

    const validateMultipleSelect = (value, setError) => {
        if (value.length < 5) { // Adjust condition based on what a "valid" option is (e.g., not null or undefined)
            setError(true);
            return false;
        }
        setError(false);
        return true;
    }

    // Handle the next button click
    const handleNext = async () => {
        const currentStage = stages[stage - 1];

        // If there is a validate function for the current stage, call it
        if (currentStage.validate && !currentStage.validate()) {
            return; // Stop here if validation fails
        }

        // Submit
        if (stage === stages.length - 1) {
            // Api call to save the survey
            setIsBottomLoading(true);
            const response = await myAxiosInstance.post('/api/save-survey-data/', { spreadsheet_id: spreadsheet, survey_data: collectSurveyData() });
            
            // Reset Typing
            setIsTypingDone(true);
            setIsBottomLoading(false);
        } else {
            setIsTypingDone(false);
        }

        // Next stage
        // Determine the next stage
        let nextStage = stage + 1;
        if (stage == 29 && selectedPeoplePool.current === "B") {  // skip linkedin / resume if no matched pool
            nextStage = 33;
        } else if (stage == 33 && bespokeInterest.current === "A") {  // go to linkedin / resume if bespoke
            nextStage = 31;
        } else if (stage == 32 && bespokeInterest.current !== "")  { // skip bespoke if already asked
            nextStage = 34;
        }


        if (stage === stages.length) {
            // Go to signup
            navigate("/signup");
        } else {
            setStage(nextStage);
        }
    };

    // Handle previous button click
    const handlePrev = () => {
        if (stage > 1) {
            if (stage === 2){
                setIsTypingDone(true);
            } else {
                setIsTypingDone(false)
            }

            // Get the prev stage
            let nextStage = stage - 1;
            if (stage == 33) {
                nextStage = 29;
            }

            setStage(nextStage);
        }
    }

    const handleTypingDone = () => {
        setIsTypingDone(true); // Update state in parent when typing is done
    };

    // User Interaction
    const handleSaveName = (value) => {
        name.current = value;
        setNameError(false);
    };

    const handleCollegeBestTime = (value) => {
        collegeBestTime.current = value;
        setCollegeBestTimeError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleKeepInContact = (value) => {
        keepInContact.current = value;
        setKeepInContactError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleDiverseEnvironment = (value) => {
        diverseEnvironment.current = value;
        setDiverseEnvironmentError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleCareerOptionality = (value) => {
        careerOptionality.current = value;
        setCareerOptionalityError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleCollegeNetworkStrength = (value) => {
        collegeNetworkStrength.current = value;
        setCollegeNetworkStrengthError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handlePoolingExcitesChoice = (value) => {
        poolingExcitesChoice.current = value;
        setPoolingExcitesChoiceError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleSelectingPeople = (person) => {
        if (selectedPeople.current.includes(person)) {
            // Remove the person from the array
            selectedPeople.current = selectedPeople.current.filter((p) => p !== person);
        } else {
            // Add the person to the array
            selectedPeople.current = [...selectedPeople.current, person];
        }
    }

    const handleBigClubPool = (value) => {
        bigClubPool.current = value;
        setBigClubPoolError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleSelectedPeoplePool = (value) => {
        selectedPeoplePool.current = value;
        setSelectedPeoplePoolError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleSavePhone = (value) => {
        phone.current = value;
        setPhoneError(false);
    };
    
    const handleSaveLinkedin = (value) => {
        linkedin.current = value;
    };

    const handleSaveResume = (value) => {
        if (value === null) {
            resume.current = null;
        } else {
            resume.current = {"name": value.name}
        }
    }

    const handleBespokeInterest = (value) => {
        bespokeInterest.current = value;
        setBespokeInterestError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleSaveOtherComments = (value) => {
        otherComments.current = value;
    };


    ///// The structure
    // 1) Intro page
    // 2) FirstPage
    const firstPageText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Let's introduce ourselves. We're ", wrapper: "span", style: {} },
                { content: "Prospinity", wrapper: "strong", style: {} },
                { content: ", a fintech and social startup that has raised funding from the investors behind ", wrapper: "span", style: {} },
                { content: "Robinhood, Venmo, Solana, and Sam Altman.", wrapper: "strong", style: {} },
            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} },
                { content: "We've operated invite-only in the Ivy League so far, but are stepping out of stealth mode and launching at colleges on the West Coast.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 3) Second Page
    const secondPageText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "What do we do? ", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
                { content: "We allow people to ", wrapper: "span", style: {} },
                { content: "build communities with directly aligned incentives to help each other succeed.", wrapper: "i", style: {} },            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} },
                { content: "Depending on your personality, this may sound cool, meh, or totally unappealing. But before we unpack what it means, let's back up...", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 4) Name Page
    // const namePageText = [
    //     {
    //         sequences: [
    //             { content: 700, wrapper: "span", style: {} }, // WAITING
    //             { content: "First, we want to know more about you. ", wrapper: "span", style: {} },
    //             { content: 500, wrapper: "span", style: {} },
    //             { content: "What's your name?", wrapper: "span", style: {} },
    //             { content: 300, wrapper: "span", style: {} },
    //         ]
    //     },
    // ];
    const namePageText = [
        "First, we want to know more about you. What's your name?"
    ]

    // 5) College Intro
    const collegeIntroText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: `Okay, ${name.current}. `, wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "We want you to think about college... ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 6) College 2nd page
    // const collegeSecondText = [
    //     {
    //         sequences: [
    //             { content: 700, wrapper: "span", style: {} }, // WAITING
    //             { content: "Many people, reflecting back, would say college was one of the best times in their life. Why do you think that is?", wrapper: "span", style: {} },
    //             { content: 300, wrapper: "span", style: {} },
    //         ],
    //     },
    // ];
    const collegeSecondText = [
        "Many people, reflecting back, would say college was one of the best times in their life. Why do you think that is?"
    ]
    const collegeSecondOptions = {
        "A": "Constantly having the opportunity to meet new people and build meaningful connections.",
        "B": "Freedom to explore — switching majors, joining clubs, or trying internships — without locking into a single path.",
        "C": "Sharing similar schedules, potential, and spaces with a cohort at the same stage of life.",
        "D": "Can't imagine why, college sucks imo."
    };


    // 7) College 3rd page
    // const collegeThirdText = [
    //     {
    //         sequences: [
    //             { content: 700, wrapper: "span", style: {} }, // WAITING
    //             { content: "In college, you come across so many interesting people doing so many different things. Do you think you’ve built out your college network to the fullest?", wrapper: "span", style: {} },
    //             { content: 300, wrapper: "span", style: {} },
    //         ],
    //     },
    // ];
    const collegeThirdText = [
        "In college, you come across so many interesting people doing so many different things. Do you think you've built out your college network to the fullest?"
    ]
    const collegeThirdOptions = {
        "A": "I wish I'd gotten closer with many different people.",
        "B": "Nope, I did super well in meeting people and staying connected.",
    };

    // 8) Career Intro
    const careerIntroPage = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Let's fast forward to a few years later. ", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
                { content: "Now you have a career...", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 9) Career Page 1
    const careerPageOneText = [
        "How well do you think you will keep in contact with the people you've met in college?"
    ]
    const careerPageOneOptions = {
        "A": "Hopefully a few close ones, but most will probably fade away.",
        "B": "All of them! I'm really good at maintaining relationships.",
    };

    // 9) Career Page 2
    const careerPageTwoText = [
        "Now that you have a job and career, how diverse and stimulating do you think the intellectual and social environment will be?"
    ]
    const careerPageTwoOptions = {
        "A": "I'll know a lot about my industry and meet people within it, but it will get difficult to branch out.",
        "B": "Just as vibrant as college.",
    };

    // 9) Career Page 2
    const careerPageThreeText= [
        "Do you think it'll be easier to change what you are doing, or more difficult, as life progresses?"
    ]
    const careerPageThreeOptions = {
        "A": "More difficult - most of us ultimately get locked into one career path.",
        "B": "Nah, my optionality will be the same as it was in college.",
    };

    // 10) Monologue
    const monologue1 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: `Here's the truth, ${name.current}.`, wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 11) Monologue 2
    const monologue2 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "After college, you'll slowly get locked into your career path.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Your life will get busier, you'll spend 80% of your time with the same colleagues, and many of your college friendships will fade away.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 12) Monologue3
    const monologue3 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Your college peers will take different paths.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Some will be at the ", wrapper: "span", style: {} },
                { content: "right place at the right time ", wrapper: "strong", style: {} },
                { content: "and get luckier than others.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Others will struggle, often based on ", wrapper: "span", style: {} },
                { content: "factors out of their control.", wrapper: "strong", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 13) Monologue4
    const monologue4 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "That's the way of things, right?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 14) Monologue5
    const monologue5 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Not anymore.", wrapper: "span", style: {} },
                { content: 1000, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "At Prospinity, we give people back that optionality, the feeling of having that crew of friends you had in college, and the chance to play at different levels and paths of life, regardless of luck.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 15) Monologue6
    const monologue6 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "How? ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "By joining a ", wrapper: "span", style: {} },
                { content: "Success Pool.", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "It's a ", wrapper: "span", style: {} },
                { content: "deal you make with a group of people you believe in.", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "So far, selected students from East Coast schools like MIT, Harvard, and Princeton have joined success pools on Prospinity's platform. Many of them have offers from companies like Goldman Sachs, Citadel, SpaceX and McKinsey.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Now we're giving the same opportunity to you.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 16) Monologue7
    const monologue7 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Here's how the deal works...", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "First, you select your terms:", wrapper: "strong", style: {} },
            ],
        },
        {
            sequences: [
                { content: "A success token: a small % of your earnings.", wrapper: "span", style: {} },
                { content: 100, wrapper: "br", style: {} },
                { content: "Pool duration: how long the deal lasts.", wrapper: "span", style: {} },
                { content: 100, wrapper: "br", style: {} },
                { content: "The participants: who else is in the deal.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Then, every year after graduation, for the duration, ", wrapper: "strong", style: {} },
                { content: "you split your success token with the entire group evenly.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Prospinity runs the math. ", wrapper: "strong", style: {} },
                { content: "You either contribute or receive money for that year.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 17) Monologue8
    const monologue8 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "In this specific year, Alex's success token is 1% of $150k = $1.5k. He splits this with the group he pooled with.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "But everyone else's success token is also split evenly. Alex earns $3k.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Prospinity pays him the net amount = $1.5k.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 18) Monologue9
    const monologue9 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Why are students doing this?", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Here are a few ways to think about it...", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 19) Monologue10
    const monologue10 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "First, turn the game of life into multiplayer.", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Now, you are playing in team. Since you are invested in each other, your teammates will help you succeed.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 20) Monologue11
    const monologue11 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Second, multiply your chances of achieving extraordinary success.", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "The chances of a 10-person pool to hit it big (e.g. make $1B) are 10x the chances of an individual.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "For the person who hits it big, almost nothing changes. For the others, being part of the pool means a life-changing payout.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];


    // 21) Monologue12
    const monologue12 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Third, rely on a portfolio of careers, rather than just yours.", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Your career is your biggest asset. Why limit yourself to one shot? Amidst uncertainty, by joining a pool, you can hedge the downside and share in the upside of diverse life paths.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];


    // 22) Monologue13
    const monologue13 = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Remember that fantastic college network that will go on to do different things? The one you wish you were closer to and could stay in contact with?", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "A pool is a way to say - I want to be part of your life journey. I want you to share in your success and I want you to share in my success, wherever we are in the future.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "We'll stay committed to helping each other out, because we're ", wrapper: "span", style: {} },
                { content: "invested ", wrapper: "i", style: {} },
                { content: "in each other.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 23) Pooling excites question
    // const poolingExcitesText = [
    //     {
    //         sequences: [
    //             { content: 700, wrapper: "span", style: {} }, // WAITING
    //             { content: "What aspect of joining a success pool excites you most?", wrapper: "span", style: {} },
    //             { content: 300, wrapper: "span", style: {} },
    //         ],
    //     },
    // ];
    const poolingExcitesText = [
        `Okay, ${name.current}. Now that you understand Prospinity, what aspect of joining a success pool would excite you most?`
    ]
    const poolingExcitesOptions = {
        "A": "Joining a network where members are directly invested in each other and incentivized to help you succeed.",
        "B": "Building a diversified “career portfolio” of people taking different life paths and journeys.",
        "C": "The chance of someone in your group hitting it big.",
    };

    // 24) Transition
    const clubPoolTransitionText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "You're close to the $10 bonus!", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "And even closer to building your ideal success pool.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 25) Select People
    const selectPeopleText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: `Here are 20 random people from ${capitalizeClubName(club_name)}. `, wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "Choose the 10 people you'd most want in your pool (and who you think would want you in theirs).", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
    ];

    // 26) Big Club Pool
    const bigClubPoolText = [
        `If ${capitalizeClubName(club_name)} were to create a Success Pool of its members, would you be interested in receiving an invite?`
    ]
    const bigClubPoolOptions = {
        "A": "Definitely - I'd be interested",
        "B": "No, I'd prefer a smaller pool",
    };

    // 27) Selected People Pool
    const selectedPeoplePoolText = [
        "Prospinity is creating some selected pools of high performing students at Berkeley. Would you be interested in learning more?"
    ]
    const selectedPeoplePoolOptions = {
        "A": "Yes, I'd like to learn more.",
        "B": "No",
    };
    
    // 28) Phone number
    const phoneText = [
        "Leave your phone number below. We'll reach out if you are selected."
    ]

    // 29) Linedin number
    const linkedinText = [
        "(Optional) If you have a LinkedIn profile, leave the link below. This helps us with the selection process."
    ]

    // 30) Resume
    const resumeText = [
        "(Optional) If you have a resume, drop it below. This helps us with the selection process."
    ]

    // 31) Bespoke Intro
    const bespokeIntroText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Introducing ", wrapper: "span", style: {} },
                { content: "Prospinity Bespoke", wrapper: "strong", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: `As part of our partnership with ${capitalizeClubName(club_name)}, we are giving access to `, wrapper: "span", style: {} },
                { content: "Prospinity Bespoke ", wrapper: "strong", style: {} },
                { content: "to a few selected students that are interested in joining a pool now or in the future.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Bespoke is an exclusive program that allows members to fully customize their own pools and unlock additional features.", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Would you be interested in getting access to Prospinity Bespoke?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ]
    const bespokeIntroOptions = {
        "A": "Yes, I'd like to learn more.",
        "B": "No",
    };

    // 32) Linedin number
    const otherCommentsText = [
        "Any other questions/comments?"
    ]

    // 33) Final Step Page
    const finalStepText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "As the final step of the partnership, you'll create an account on Prospinity.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Creating an account ", wrapper: "span", style: {} },
                { content: "does not mean ", wrapper: "strong", style: {} },
                { content: "joining a pool.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "However, having an account gives you the opportunity to be invited to a pool or to create one. You have complete control over which pools to join and how many.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Once your account is set up, you can redeem your $10 partnership bonus directly on the platform using Venmo or PayPal.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 34) End Page

    const stages = [
        { component: (<IntroPage />), validate: null, buttonName: "I'm Ready", animation: introAnimationSettings, },
        { component: (<SimpleTextPage paragraphs={firstPageText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={secondPageText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleInputTextField paragraphs={namePageText} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} inputRef={name} handleInput={handleSaveName} inputError={nameError} secondaryText="(First name only...)" />), validate: () => validateString(name.current, setNameError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={collegeIntroText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={collegeSecondText} options={collegeSecondOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={collegeBestTime} handleChoice={handleCollegeBestTime} choiceError={collegeBestTimeError} />), validate: () => validateOption(collegeBestTime.current, setCollegeBestTimeError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={collegeThirdText} options={collegeThirdOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={collegeNetworkStrength} handleChoice={handleCollegeNetworkStrength} choiceError={collegeNetworkStrengthError} />), validate: () => validateOption(collegeNetworkStrength.current, setCollegeNetworkStrengthError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={careerIntroPage} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={careerPageOneText} options={careerPageOneOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={keepInContact} handleChoice={handleKeepInContact} choiceError={keepInContactError} />), validate: () => validateOption(keepInContact.current, setKeepInContactError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={careerPageTwoText} options={careerPageTwoOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={diverseEnvironment} handleChoice={handleDiverseEnvironment} choiceError={diverseEnvironmentError} />), validate: () => validateOption(diverseEnvironment.current, setDiverseEnvironmentError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={careerPageThreeText} options={careerPageThreeOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={careerOptionality} handleChoice={handleCareerOptionality} choiceError={careerOptionalityError} />), validate: () => validateOption(careerOptionality.current, setCareerOptionalityError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue1} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue2} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue3} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue4} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue5} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<TextWithImage paragraphs={monologue6} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} image={PlatformPicture} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue7} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<TextWithSideImage paragraphs={monologue8} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} image={PoolGraphic} secondaryText="See the example graphic." />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue9} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue10} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue11} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue12} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={monologue13} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={poolingExcitesText} options={poolingExcitesOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={poolingExcitesChoice} handleChoice={handlePoolingExcitesChoice} choiceError={poolingExcitesChoiceError} secondaryText="There's no right or wrong answer." />), validate: () => validateOption(poolingExcitesChoice.current, setPoolingExcitesChoiceError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={clubPoolTransitionText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<MultiSelectPage paragraphs={selectPeopleText} options={peopleOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} selectionRef={selectedPeople} handleSelect={handleSelectingPeople} selectError={selectedPeopleError} />), validate: () => validateMultipleSelect(selectedPeople.current, setSelectedPeopleError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={bigClubPoolText} options={bigClubPoolOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={bigClubPool} handleChoice={handleBigClubPool} choiceError={bigClubPoolError} secondaryText="Note that receiving the invite is non-binding. It just means you will have the option to decide whether to join or not." />), validate: () => validateOption(bigClubPool.current, setBigClubPoolError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={selectedPeoplePoolText} options={selectedPeoplePoolOptions} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={selectedPeoplePool} handleChoice={handleSelectedPeoplePool} choiceError={selectedPeoplePoolError} />), validate: () => validateOption(selectedPeoplePool.current, setSelectedPeoplePoolError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleInputTextField paragraphs={phoneText} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} inputRef={phone} handleInput={handleSavePhone} inputError={phoneError} />), validate: () => validateString(phone.current, setPhoneError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleInputTextField paragraphs={linkedinText} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} inputRef={linkedin} handleInput={handleSaveLinkedin} inputError={false} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<FileUploadPage paragraphs={resumeText} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} fileRef={resume} handeFile={handleSaveResume} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={bespokeIntroText} options={bespokeIntroOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={bespokeInterest} handleChoice={handleBespokeInterest} choiceError={bespokeInterestError} />), validate: () => validateOption(bespokeInterest.current, setBespokeInterestError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleInputTextField paragraphs={otherCommentsText} typing={false} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} inputRef={otherComments} handleInput={handleSaveOtherComments} inputError={false} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={finalStepText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Finish Tutorial", animation: animationSettings },
        { component: (<EndPage />), animation: introAnimationSettings, buttonName: "Create Your Account" },
    ];
    

    return (
            <>
                <div
                    className="d-flex justify-content-center align-items-start"
                    style={{
                        backgroundColor: "#02002B",
                        width: "100%",
                        height: "100vh",
                        overflowY: "auto", // Ensures content scrolls if it overflows the viewport
                    }}
                >
                    <div
                        className="home-joyride d-flex justify-content-center"
                        style={{ width: "100%", position: "relative" }}
                    >
                        {/* Logo Fixed in Position */}
                        <div
                            className="d-flex justify-content-start align-items-center"
                            style={{
                                position: "fixed", // Fix the logo position
                                top: isMdOrLarger ? "3rem" : "1.5rem", // Adjust location for screen size
                                left: isMdOrLarger ? "3rem" : "1.5rem",
                                zIndex: 10, // Ensure it stays above other elements
                            }}
                        >
                            <img
                                src={WhiteLogo}
                                alt="Preview"
                                onError={(e) => {
                                    e.target.src =
                                        "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp";
                                }}
                                style={{
                                    width: isMdOrLarger ? "14rem" : "10rem",
                                }}
                            />
                        </div>

                        {/* Content Expanding Downwards */}
                        <div
                            className="d-flex flex-column"
                            style={{
                                width: isMdOrLarger ? "60%" : "80%",
                                color: "white",
                                marginTop: isMdOrLarger ? "12rem" : "7rem", // Ensure content starts below the logo
                                marginBottom: "3rem",
                            }}
                        >
                            <div
                                style={{
                                    color: "white",
                                    fontSize: isMdOrLarger ? "1.6rem" : "1.2rem",
                                }}
                            >
                                
                                <div>
                                    <AnimatePresence mode="wait">
                                        {stages[stage - 1] && (
                                            <motion.div
                                                key={`stage-${stage}`} // Ensure unique key for each stage
                                                variants={stages[stage - 1].animation.variants}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                transition={stages[stage - 1].animation.transition}
                                            >
                                                {stages[stage - 1].component}
                                            </motion.div>
                                        )}
                                                   
                                        {/* Button aligned to the left, starting where the text starts */}
                                        {isTypingDone && (
                                            <>
                                            <motion.div
                                                key="button"
                                                variants={isMdOrLarger ? {
                                                        initial: { opacity: 0, y: 300 }, // Start off-screen and fade in
                                                        animate: { opacity: 1, y: 0 }, // Slide to position
                                                        exit: { opacity: 0, y: -300 }, // Fade out and slide up
                                                    } :
                                                    {
                                                        initial: { opacity: 0, y: 0 }, // Start off-screen and fade in
                                                        animate: { opacity: 1, y: 0 }, // Slide to position
                                                        exit: { opacity: 0, y: 0 }
                                                    }
                                            
                                                }
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                transition={{
                                                    initial: { duration: 1.2 }, // Duration for entering animation
                                                    exit: { duration: 1.2 },     // Duration for exiting animation
                                                }}
                                            >
                                                <div className={`d-flex ${stage === stages.length ? 'justify-content-center' : 'justify-content-start'}`} style={{ marginTop: isMdOrLarger ? '1.3rem' : '1rem', marginBottom: '1rem' }}>
                                                    {isBottomLoading ? <Loading /> :
                                                        <SurveyButton text={stages[stage - 1].buttonName} handleNext={handleNext} isFirstPage={stage === 1} handleBack={handlePrev} />
                                                    }
                                                    {isMdOrLarger && (
                                                        <span
                                                            style={{
                                                                marginLeft: "1rem",
                                                                display: "flex", // Use flexbox to align all items
                                                                alignItems: "center", // Ensures vertical alignment
                                                                color: "white",
                                                                fontSize: "0.9rem", // Adjust font size as needed
                                                            }}
                                                        >
                                                            press 
                                                            <strong style={{ margin: "0 0.3rem"}}>Enter</strong>
                                                            <span
                                                                style={{
                                                                    fontFamily: "monospace",
                                                                    fontSize: "1.2rem", // Match the text size
                                                                    lineHeight: "1", // Ensure consistent line height for alignment
                                                                    marginBottom: "0.25rem",
                                                                }}
                                                            >
                                                                ↵
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                                
                                            </motion.div>
                  
                                            {stage !== stages.length && isMdOrLarger && <motion.div
                                                key="button"
                                                variants={{
                                                    initial: { opacity: 0, y: 0 }, // Start off-screen and fade in
                                                    animate: { opacity: 1, y: 0 }, // Slide to position
                                                    exit: { opacity: 0, y: -0 }, // Fade out and slide up
                                                }}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                transition={{
                                                    initial: { duration: 1.2 }, // Duration for entering animation
                                                    exit: { duration: 1.2 },     // Duration for exiting animation
                                                }}
                                            >
                                                <SurveyNavigationButtons onNextClick={handleNext} onPrevClick={handlePrev} />
                                            </motion.div>}
                                            </>
                                        )}
                                    </AnimatePresence>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>


    );

}

export default CommunitySurvey;